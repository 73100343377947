
import { defineComponent } from 'vue'
import TmTabs from '@/components/shared/TmTabs.vue'
import type { Tab } from '@/components/shared/TmTabs.vue'

export default defineComponent({
  components: {
    TmTabs,
  },
  setup() {
    const tabs: Tab[] = [
      {
        name: 'first',
        label: 'First tab',
        counter: 1,
      },
      {
        name: 'second',
        label: 'Second tab',
        counter: 0,
      },
      {
        name: 'third',
        label: 'Third tab',
      },
    ]

    return {
      tabs,
    }
  },
})
